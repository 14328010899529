<template>
    <div>
        <header-portrate />

    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        <div class="px-3 py-2" style="direction:rtl;width:500px;margin:auto">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="اسم المستخدم *"
                        v-model="user.username"
                        >{{ user.username }}</v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="الاسم الكامل *"
                        v-model="user.full_name"
                        >{{ user.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="كلمة المرور *"
                        v-model="password"
                        type="password"
                        ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="group_id"
                        :items="perms"
                        label="مجموعة الصلاحيات"
                    ></v-select>
                </v-col>
                <div style="color:red;font-size:.7em">اذا لم تقم باختيار المجموعة ستبقى الصلاحيات الحالية كما هي</div>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn class="btn-sm btn success" @click="updateUser()">{{lang.save}}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
  components: { BreadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            user: {},
            group_id: 0,
            password: '',
            perms:[
                {
                    text: 'موظف كاشير',
                    value: 3,
                },
                {
                    text: 'محاسب',
                    value: 2,
                },
                {
                    text: 'ادارة عامة',
                    value: 1,
                },
            ],
            
        }
    },
    computed: {
       
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items:function () {
            return {
                text: this.lang.users,
                disabled: true,
                to: '/settings/users-settings',
            }
        },
        tabs: function() {
            return [
                {
                    index:0,name:'اعدادات النظام',href:'/settings/system-settings', class: 'mytab1'
                },
                {
                    index:1,name:'المستخدمين',href:'/settings/users-settings', class: 'mytab'
                },
                {
                    index:2,name:'الصلاحيات',href:'/settings/permissions-settings', class: 'mytab3'
                }
            ]
        }
    },
    methods:{
        getUsers(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[userid]',this.$route.params.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res);
                    this.user = res.results.data.results[0];
                    this.group_id = this.user.group_id;
                }
            )
        },
        updateUser() {
            const post = new FormData();
            post.append('type', 'updateUser');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.$route.params.id);
            post.append('data[username]',this.user.username);
            post.append('data[full_name]',this.user.full_name);
            post.append('data[password]',this.password);
            post.append('data[group_id]',this.group_id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                
                this.$router.push({path: '/settings/users-settings'})
            })
        }
    },
    created() {
        this.getUsers();
        
    },
}
</script>